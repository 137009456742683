*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.demo-text1{
  overflow: hidden;
  font-size: clamp(8rem, 15vw, 16rem);
  margin-top: 0;
  font-weight: 900;
  display: flex;
  flex-direction: column;

  .wrapper1:nth-child(2){
    text-align: right;
    margin-left: -40rem;
  }

  @media screen and (max-width: 663px){
    .wrapper1{
      font-size: clamp(8rem, 8vw, 10rem);
    }
  }
}

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.footer_container{
  width: 100%;
  margin-top: 10rem;
  background-color: #42295E;

  .first_section{
    width: 70%;
    margin: 0 auto;
    display: flex;
    padding-bottom: 10rem;

    @media screen and (max-width: 1300px) {
      width: 90%;
    }

    @media screen and (max-width: 915px) {
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .footer_title{
      margin-top: 1rem;
      width: 70%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 20px;

      @media screen and (max-width: 915px) {
        width: 100%;
        align-items: center;
        text-align: center;
      }

      .footer_logo{
        margin-top: 10rem;
        display: flex;
        align-items: center;
        gap: 20px;

        p{
          font-size: 30px;
          font-weight: 500;
          color: rgba(255, 255, 255, 0.18);
        }
      }

      .footer_desc{
        width: 50%;
        font-size: 20px;
        font-weight: 300;
        color: whitesmoke;
        margin-top: 2rem;
        opacity: .8;
        margin-bottom: 5rem;

        @media screen and (max-width: 915px) {
          width: 90%;
        }
      }

      .footer_link{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 3rem;
        margin-bottom: 5rem;
      }

      .footer_btns{
        display: flex;
        gap: 2rem;
        align-items: flex-start;
        justify-content: flex-start;

        @media screen and (max-width: 467px) {
         flex-direction: column;
          width: 100%;
        }

        .ios{
          padding: 14px 60px;
          color: black;
          background-color: whitesmoke;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 5px;
          transition: .3s;
          gap: .5rem;
          @media screen and (max-width: 467px) {
            width: 100%;
            padding: 24px 60px;
          }
          &:hover{
            filter: brightness(80%);
            cursor: pointer;
            transition: .3s;
          }

          a{
            color:black ;
            font-weight: 500;
            cursor: pointer;
            text-decoration: none;
          }
        }
        .android{
          padding: 14px 60px;
          color: black;
          background-color: whitesmoke;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 5px;
          transition: .3s;
          gap: .5rem;
          @media screen and (max-width: 467px) {
            width: 100%;
            padding: 24px 60px;
          }
          &:hover{
            filter: brightness(80%);
            cursor: pointer;
            transition: .3s;
          }
          a{
            color:black ;
            font-weight: 500;
            text-decoration: none;
          }
        }
      }
    }

    .footer_links{
      width: 30%;
      margin-top: 10rem;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content:flex-start;
      gap: 1rem;
      padding: 0 0 0 3rem;
      border-left: 1px solid whitesmoke;

      @media screen and (max-width: 900px) {
        border: none;
        align-items: center;
        width: 100%;
        padding: 0;
      }

      a{
        text-decoration: none;
        font-size: 25px;
        color: whitesmoke;
        font-weight: 200;
        transition: .3s;

        &:hover{
          color: rgba(245, 245, 245, 0.57);
          transition: .3s;
        }
      }
    }
  }

  .copyright{
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 4rem;
    margin-top: 3rem;
    gap: .5rem;

    @media screen and (max-width: 550px) {
      padding-bottom: 10rem;
      p{
        font-size: 12px;
      }
    }

    p{
      color: white;
      font-weight: 200;
    }
  }
}
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.contact_container {
  width: 100%;
  position: relative;

  @media screen and (max-width: 1300px){
    margin-top: 50rem;
  }
  @media screen and (max-width: 500px){
    margin-top: 70rem;
  }


  .contact_title{
    width: 70%;
    margin: 20rem auto ;
    text-align: center;
    margin-bottom: 7rem;

    p {
      font-size: 22px;
      font-weight: 300;
      color: #8584bd;
      margin-bottom: 1rem;
    }

    h2 {
      font-size: 3rem;
      font-weight: 700;
    }

    @media screen and (max-width: 565px) {
      width: 100%;
      h2 {
        font-size: 2.5rem;
      }
    }
  }

  .contact_section{
    width: 90%;
    margin: 0 auto;
    padding: 10rem 5rem;
    background-color: #42295E;
    border-radius: 1rem;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 2rem;

    @media screen and (max-width: 1300px){
      width: 95%;
      padding: 10rem 3rem;
    }

    @media screen and (max-width: 1000px){
      width: 80%;
      padding: 8rem 2rem;
      flex-direction: column;
    }

    @media screen and (max-width: 700px){
      width: 100%;
      flex-direction: column;
      border-radius: 0;
      padding: 5rem 1rem;
    }


    .first_contact_sec{
      width: 100%;

      p{
        color: white;
        font-size: 20px;
        margin-bottom: 2rem;
      }

      img{
        width: 100%;
        height: 100%;
      }

      .contact_rules{
        width: 100%;
        p{
          margin-top: 10rem;
          font-size: 17px;
          font-weight: 400;
          opacity: .8;
        }
        li{
          color: white;
          opacity: .7;
          font-weight: 200;
        }
        @media screen and (max-width: 1000px){
          display: none;
        }

      }


    }
    .contact_rules_2{
      width: 100%;
      margin-top: 3rem;
      display: none;

      p{
        color: white;
        font-size: 17px;
        font-weight: 400;
        margin-bottom: 2rem;
        opacity: .8;
      }
      li{
        color: white;
        opacity: .7;
        font-weight: 200;
      }

      @media screen and (max-width: 1000px){
        display: flex;
        flex-direction: column;
      }

    }
    .second_contact_sec{
      width: 100%;
      padding: 20px 5rem;
      background-color: white;
      border-radius: 20px;

      @media screen and (max-width: 700px){
        padding: 20px 1rem;
      }

      .second_contact_sec_title{
        margin: 1rem 0 4rem;
        font-size: 1.4rem;
        font-weight: 700;
      }

      .owner_name{
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 2rem;


        @media screen and (max-width: 600px){
          flex-direction: column;
        }
      }

      .contact_send_request{
        width: 100%;
        margin-top: 2rem;
        margin-bottom: 5rem;
        background-color: #42295E;
        padding: 1.5rem 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        cursor: pointer;

        p{
          color: white;
          font-size: 18px;
          font-weight: 300;

          @media screen and (max-width: 500px){
            font-size: 12px;
          }

        }
      }
      .contact_error_message{
        width: 100%;
        font-size: 15px;
        margin-top: 1rem;
        padding: .5rem 1rem;
        color: #00be00;
      }
    }

  }
}

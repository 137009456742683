*{
  margin: 0 ;
  padding: 0;
  box-sizing: border-box;
}

.client_contact_container{
  width: 50%;
  margin: 0 auto;
  padding: 5rem 0;
  
  @media screen and (max-width: 1200px) {
    width: 70%;
  }
  @media screen and (max-width: 900px) {
    width: 90%;
  }
  @media screen and (max-width: 550px) {
    width: 100%;
    padding: 5rem 1rem;
  }


  .client_contact_header{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .go_back_btn{
      display: flex;
      align-items: center;
      justify-content: flex-start;
      cursor: pointer;
      transition: .3s;
      margin-bottom: 4rem;

      &:hover{
        opacity: .6;
        transition: .3s;
      }

      p{
        font-size: 18px;
      }
      @media screen and (max-width:600px){
        margin-top: 3rem;
      }
    }

    .support-contact{
      display: flex;
      padding: .5rem .5rem;
      background-color: #efefef;
      border-radius: 5px;
      align-items: center;
      justify-content: flex-start;
      cursor: pointer;
      transition: .3s;
      margin-bottom: 4rem;
      gap: 1rem;

      &:hover{
        opacity: .6;
        transition: .3s;
      }

      p{
        font-size: 14px;
      }

      @media screen and (max-width:600px){
        display: none;
      }
    }
  }

  .client_contact_title{
    width: 100%;
    h3{
      font-size: 3.5rem;
      font-weight: 900;
      width: 100%;
      margin-bottom: 1rem;
      color: #42295E;
      @media screen and (max-width: 550px) {
        font-size: 2.5rem;
      }
    }
    p{
      font-size: 2rem;
      @media screen and (max-width: 550px) {
        font-size: 1.5rem;
      }
    }
  }

  .client_contact_form{
    margin-top: 5rem;
    width: 100%;

    .client_name{
      display: flex;
      gap: 2rem;
      @media screen and (max-width: 550px) {
        flex-direction: column;
      }
    }

    .client_conatct_description{
      width: 100%;
      resize: none;
      outline: none;
      border: none;
      padding: 1rem 1rem;
      color: black;
      background-color: #eeeeee;
      font-weight: 300;
      font-size: 15px;
      transition: .3s;
      margin-bottom: 1rem;
      box-shadow: 2px 3px 5px rgba(0, 0, 0, 0.06);
    }

    .client_contact_error_message{
      width: 100%;
      font-size: 15px;
      margin-top: 1rem;
      padding: .5rem 1rem;
      color: #00be00;
    }

    .client_contact_send_request{
      width: 100%;
      margin-top: 2rem;
      margin-bottom: 5rem;
      background-color: #42295E;
      padding: 1.5rem 2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      cursor: pointer;

      p{
        color: white;
        font-size: 18px;
        font-weight: 300;

        @media screen and (max-width: 500px){
          font-size: 12px;
        }

      }
    }
  }

  .client_btns_title{
    margin-top: 8rem;
    width: 100%;
    text-align: center;
    margin-bottom: 3rem;
    font-size:1.5rem;
    font-weight: 200;
    @media screen and (max-width: 550px) {
      font-size: 1.2rem;
    }
  }
  .client_btns{
    width: 100%;
    display: flex;
    gap: 2rem;
    align-items: flex-start;
    justify-content: flex-start;

    @media screen and (max-width: 467px) {
      flex-direction: column;
      width: 100%;
    }

    .client_ios{
      width: 100%;
      padding: 2rem 60px;
      color: black;
      background-color: whitesmoke;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
      transition: .3s;
      gap: .5rem;
      @media screen and (max-width: 467px) {
        width: 100%;
        padding: 24px 60px;
      }
      &:hover{
        filter: brightness(80%);
        cursor: pointer;
        transition: .3s;
      }

      a{
        color:black ;
        font-weight: 500;
        cursor: pointer;
        text-decoration: none;
      }
    }
    .client_android{
      width: 100%;
      padding: 2rem 60px;
      color: black;
      background-color: whitesmoke;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
      transition: .3s;
      gap: .5rem;
      @media screen and (max-width: 467px) {
        width: 100%;
        padding: 24px 60px;
      }
      &:hover{
        filter: brightness(80%);
        cursor: pointer;
        transition: .3s;
      }
      a{
        color:black ;
        font-weight: 500;
        text-decoration: none;
      }
    }
  }
  .support-contact_footer{
    position: absolute;
    top: 1rem;
    right: 1rem;
    display: none;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    transition: .3s;
    margin-bottom: 4rem;
    gap: 1rem;
    padding: .5rem 1rem;
    background-color: #efefef;
    border-radius: 5px;

    &:hover{
      opacity: .6;
      transition: .3s;
    }

    p{
      font-size: 14px;
    }

    @media screen and (max-width:600px){
      display: flex;
      p{
        font-size: 12px;
      }
    }
  }
}
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.information{
  margin-top: 20rem;
  height: 70vh;
  position: relative;

  .app_descritpion{
    width: 50%;
    margin: 0 auto;
    text-align: center;
    p{
      font-size: 22px;
      color: #bdb4ff;
      opacity: .6;
      font-weight: 200;
      margin-bottom: 30px;
    }
    h3{
      font-size: clamp(2rem, 5vw, 2.5rem);
      font-weight: 600;
      color: rgba(245, 245, 245, 0.03);
    }
    @media screen and (max-width: 1300px){
      width: 70%;
      h3{
        font-size: clamp(2rem, 5vw, 2rem);
      }
    }
  }

  .scrollBtn{
    width: 100%;
    align-items: center;
    text-align: center;
    color: whitesmoke;
    margin: 14rem auto 20rem;
    opacity: .4;

    p:nth-child(1){
      margin-top: 2rem;
      margin-bottom: 20px;
    }
  }

  @media screen and (max-width: 663px){
    .app_descritpion{
      width: 90%;

      p{
        font-size: 20px;
      }
      h3{
        font-size: clamp(1.5rem, 5vw, 1.5rem);
      }
    }

    .scrollBtn{
      margin: 8rem auto 20rem;
      p:nth-child(1){
        margin-top: 1rem;
      }
    }
  }


}
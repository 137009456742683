@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  //scroll-behavior: smooth;
}

body{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  background-color: #fff;
}

input{
  font-family: "Poppins", sans-serif;
}
.mainApp {
  position: relative;
  background: url("../src/assets/images/deskt.png") no-repeat;
  width: 100%;
  margin: 0 auto;
  z-index: 1;



  @media screen and (max-width: 1200px ){
    width: 100%;
    height: 100vh;
    background-size: contain;
    background-position: bottom;
  }
  @media screen and (max-width: 1000px ){
    background: none;
  }
}

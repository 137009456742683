*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


.mainBanner{
  margin: 150px auto 250px;
  width: 100%;
  position: relative;
  display: flex;
  height: 40rem;
  flex-direction: column;
  justify-content: center;
  background-color: #000000;

  .scroller{
    width: 70%;
    margin: -40px auto 40px;
    position: absolute;
    height: 45rem;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    background-color: #ffffff;
    padding: 50px;
    gap: 50px;
    justify-content: center;
    z-index: 100000;

    &::after{
      content: "";
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 90%;
      z-index: -10;
      height: 100%;
      background: rgb(0, 0, 0);
      border-radius: 20px;
    }


    .scrollSec{
      width: 24%;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: -40%;
      justify-content: center;
      gap: 20px;

      img{
        width: 100%;
        height: 20%;
      }
    }

    .scrollSec:nth-child(2){
      margin-top: -30%;
    }
  }
}

@media screen and (max-width: 663px){
  .mainBanner{
    margin: 150px auto 250px !important;
    height: 35rem;

    .scroller{
      height: 40rem;
      width: 90%;
      .scrollSec{
        width: 50%;
        margin-top: -80%;
      }
      .scrollSec:nth-child(3){
        display: none;
      }
    }
  }
}

@media screen and (max-width: 663px){
  .mainBanner{
   margin-bottom: -40rem;

    .scroller{
      height: 40rem;
      .scrollSec{
        width: 50%;
        margin-top: -80%;
      }
      .scrollSec:nth-child(3){
        display: none;
      }
    }
  }
}

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.business_container{
  width: 100%;
  position: relative;
  height: 100vh !important;

  .business_title{
    width: 70%;
    margin: 0 auto;
    text-align: center;
    margin-bottom: 7rem;

    p{
      font-size: 22px;
      font-weight: 300;
      color: #8584bd;
      margin-bottom: 1rem;
    }
    h2{
      font-size: 3rem;
      font-weight: 700;
    }

    @media screen and (max-width: 565px){
      width: 100%;
      h2{
        font-size: 2.5rem;
      }
    }
  }

  .business_spacer{
    height: 20vh;
  }
  .spline{
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 70vh;
    padding-left: 5rem;
    overflow: hidden;
    opacity: .5;
    image{
      width: 100%;
      margin: 0 auto;
      background-position: center;
    }
  }

  .business_cards{
    position: absolute;
    top: 60%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    left: 0;
    width: 100%;
    z-index: 100;

    .business_card{
      width: 20rem;
      border-radius: 10px;
      height: 20rem;
      background: rgba(255, 255, 255, 0.2);
      padding: 20px;
      box-shadow: 0 1px 5px rgba(135, 112, 255, 0.26);
      backdrop-filter: blur(6px);
      border: 1px solid rgba(255, 255, 255, 0.3);
      color: #42295e;
      text-align: center;

      img{
        margin-top: 10px;
        margin-bottom: 30px;
      }
      .business_card_text{
        p{
          font-weight: 300;
        }
      }

      @media screen and (max-width: 1000px){
        width: 15rem;
        padding: 0;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.13);
      }
      @media screen and (max-width: 515px){
        width: 20rem;
        height: 15rem;
        padding: 0 10px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.13);
      }
    }
    @media screen and (max-width: 1000px){
      gap: 20px;
      padding: 3rem;
    }
    @media screen and (max-width: 700px){
      gap: 20px;
      padding: 1rem;
    }
    @media screen and (max-width: 515px){
      .spline{
        display: none;
      }
    }

  }
  @media screen and (max-width: 515px){
    .spline{
      height: 200vh;
    }
  }
}
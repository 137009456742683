@import url("https://fonts.googleapis.com/css?family=Signika+Negative:300,400&display=swap");

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}



.instructions_container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.panel {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2em;
  background-color: #fff;
}


@media screen and (max-width: 1200px) {
  .panel{
    align-items: flex-start;
    justify-content: flex-start;
    height: 100vh;
  }
}

.panel:nth-child(odd) {
  background-color: #eee;
}

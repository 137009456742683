*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.terms_container{
  width: 50%;
  margin: 5rem auto 8rem;
  font-family: "Poppins", sans-serif;

  @media screen and (max-width: 1000px){
    width: 80%;
  }

  @media screen and (max-width: 600px){
    width: 100%;
    padding: 0 1rem;
  }
  .go_back_terms{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    gap: 1rem;
    transition: .3s;
    margin-bottom: 7rem;

    &:hover{
      opacity: .6;
      transition: .3s;
    }

    p{
      font-size: 18px;
    }
    @media screen and (max-width:600px){
      margin-top: 3rem;
    }
  }
  h1, h2 {
    color: #333;
  }
  section {
    margin-bottom: 30px;
    &:nth-child(1){
      margin-bottom: 5rem;
      font-size:3rem;
    }
    .terms_updated{
      margin-bottom: 2rem;
      font-size: 1.2rem;
    }

    .terms_welcome{
      margin-bottom: 2rem;
    }

    .terms_padding{
      padding: 0 0 0 1.8rem;
      margin-bottom: 1rem;
    }
    .terms_padding2{
      padding: 0 0 0 4rem;
      margin-bottom: 1rem;
    }

    .terms_policy{
      margin-top: 4rem;
      font-size: 2.5rem;
    }
  }
}
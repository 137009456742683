*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.headerMain{
  width: 80%;
  padding: 40px 170px;
  margin: 0 auto -40px;

  .fixedNav{
    width: 40%;
    position: fixed;
    bottom: 2rem;
    border-radius: 10px;
    left: 30%;
    padding: 10px 0;
    display: none;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    z-index: 100000000;
    li{
      list-style: none;
      a{
        text-decoration: none;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px 20px;
        border-radius: 12px;
        color: white;

        &.active{
          background: white;
          color: black;
        }
      }
    }

    @media screen and (max-width: 1200px){
      display: flex;
    }
    @media screen and (max-width: 800px){
      width: 70%;
      left: 15%;
    }
    @media screen and (max-width: 800px){
      width: 90%;
      left: 5%;
    }
  }

  .elemSection{
    .logoSection{
      h3{
        font-size: 19px;
        color: #f1f1f1;
        span{
          color: #a63afd;
          font-size: 22px;
          text-align: end;
        }
      }
    }

    .largeNavSection{
      display: flex;
      align-items: center;
      gap: 50px;
      justify-content: center;
      li{
        list-style: none;
        a{
          text-decoration: none;
          position: relative;
          color: #1a1a1a;
          font-weight: 300;
          transition: .3s;

          &::before{
            content: "";
            width: 7px;
            height: 7px;
            position: absolute;
            bottom: 0;
            transition: .4s;
            left: 50%;
            transform: translateX(-50%);
            border-radius: 50%;
            background: rgba(147, 116, 236, 0.87);
            opacity: 0;
          }
          &:hover{
           color: rgba(204, 160, 239, 0.89);
            &::before{
              bottom: -15px;
              transition: .3s;
              opacity: 1;
            }
          }

        }
      }
    }
    .languageSection{
      overflow: hidden;
      background: #7561ff;
      border-radius: 5px;

      .content__item {
         width: 100%;
         height: 100%;
         margin: 0;
         padding: 0;
         counter-increment: itemcounter;
         display: flex;
         flex-direction: column;
         align-items: center;
         justify-content: center;
         position: relative;
         z-index: 1;
         pointer-events: none;
         color: #f1f1f1;
       }

      .content__item::before {
        color: #42295E;
        position: absolute;
        top: 0;
        left: 0;
        content: counters(itemcounter, ".", decimal-leading-zero);
      }

      .button {
        pointer-events: auto;
        cursor: pointer;
        background: #7561ff;
        border: none;
        color: #f1f1f1;
        margin: 0;
        padding: 5px 15px;
        font-family: inherit;
        font-size: inherit;
        position: relative;
        display: inline-block;
      }

      .button::before,
      .button::after {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }

      li .button--calypso {
        font-weight: 400;
        text-decoration: none;
        font-size: 15px;
        color: #42295E;
        overflow: hidden;
        font-family: "Poppins", sans-serif;
        position: relative;
        border: 1px dashed transparent; // Default border that will become visible later
        transition: border-color 0.4s cubic-bezier(0.1, 0, 0.3, 1); // Match the duration of the animation

        span {
          display: block;
          position: relative;
          z-index: 10;
          color: #f1f1f1;
        }

        &:hover {
          span {
            animation: MoveScaleUpInitial 0.3s forwards, MoveScaleUpEnd 0.3s forwards 0.3s;
            mix-blend-mode: difference;
          }

          &::before {
            transform: translate3d(0, 0, 0) scale3d(2, 1, 1);
            transition: transform 0.4s cubic-bezier(0.1, 0, 0.3, 1);
          }

          &::after {
            transform: translate3d(0, 0, 0);
            transition-duration: 0.05s;
            transition-delay: 0.4s;
            transition-timing-function: linear;
          }

          // Set the border color to visible after animation
          &.button--calypso {
            border-color: #cbb8e0 !important;
          }
        }

        &::before {
          content: '';
          background: #FFFFFF;
          width: 120%;
          height: 0;
          padding-bottom: 120%;
          top: -110%;
          left: -10%;
          border-radius: 50%;
          transform: translate3d(0, 68%, 0) scale3d(0, 0, 0);
        }

        &::after {
          content: '';
          background: #FFFF;
          color: #f1f1f1;
          border-radius: 40%;
          transform: translate3d(0, -100%, 0);
          transition: transform 0.4s cubic-bezier(0.1, 0, 0.3, 1);
        }
      }

      @keyframes MoveScaleUpInitial {
        to {
          transform: translate3d(0, -105%, 0) scale3d(1, 2, 1);
          opacity: 0;
        }
      }

      @keyframes MoveScaleUpEnd {
        from {
          transform: translate3d(0, 100%, 0) scale3d(1, 2, 1);
          opacity: 0;
        }
        to {
          transform: translate3d(0, 0, 0);
          opacity: 1;
        }
      }

    }
  }

  @media screen and (max-width: 1200px){
    .elemSection{
      .largeNavSection{
        display: none;
      }
    }
  }
  @media screen and (max-width: 1000px){
    &{
     width: 100%;
      padding: 40px 120px;
    }

  }
  @media screen and (max-width: 700px){
    &{
      width: 100%;
      padding: 40px 50px;
    }

  }
  @media screen and (max-width: 470px){
    &{
      width: 100%;
      padding: 40px 10px;
    }

  }
}
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.panel_page{
  padding: 0 10rem;
  width: 100%;
  display: flex;
  gap: 10rem;
  float: right;
  align-items: center;
  justify-content: space-between;

  .panel_image_container{
    flex-grow: 1;
    float: right;
    .panel_image{
      float: right;
      width: 40rem;
      height: 30rem;
      overflow: hidden;
      text-align: right;
      img{
        width: 100%;
        height: 100%;
      }

    }
  }

  .panel_info{
    flex-grow: 2;
    float: left;
    display: flex;
    margin-top: 0 !important;
    flex-direction: column;

    h1{
      font-size: 7rem;
      font-weight: 500;
      margin-bottom: 1rem;
      color: #ffffff;
    }

    h5{
      font-size: 2rem;
      font-weight: 300;
      margin-bottom: 2rem;
      color: #e5e5e5;
    }
    p{
      font-size: 21px;
      width: 100%;
      font-weight: 200;
      margin-bottom: 1rem;
      color: rgba(229, 229, 229, 0.7);
    }

  }

  @media screen and (max-width: 1200px) {
    &{
      flex-direction: column;
      margin-top: 0;
      padding: 0 !important;
    }
    .panel_image_container{
      display: none;
    }
    .panel_info{
      h1{
        margin-top: 7rem;
      }
      margin-top: 20rem;
      width: 100%;
      text-align: center;
    }
  }


  @media screen and (max-width: 658px) {
    &{
      flex-direction: column;
      padding: 0 20px;
      .panel_info{
        flex-grow: 1;
        width: 100%;
        padding: 0 7px;
        te
        h1{
          font-size: 5rem;
          font-weight: 500;
          margin-bottom: 1rem;
          color: #ffffff;
        }

        h5{
          font-size: 2.5rem;
        }
        p{
          padding: 0 7px;
          font-size: 18px;
        }

      }

    }
  }
}


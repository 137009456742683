*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.bannerMain{
  width: 80%;
  margin: 0 auto;
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
  flex-direction: column;


   .two{
     width: 100%;
     display: flex;
     align-items: center;
     justify-content: center;
     flex-direction: column;
     margin-top: 100px;
     @media screen and (max-width: 1200px){
       margin-top: 180px;
     }

     h1{
       font-weight:600;
       text-align: center;
       margin: 0 auto 10px;
       width: 55%;
       line-height: 60px;
       font-size: 60px;
       opacity: .8;
       color: #1a1a1a;
       @media screen and (max-width: 1200px ){
         width: 70%;
       }
       @media screen and (max-width: 1000px ){
         width: 80%;
       }
       @media screen and (max-width: 800px){
         width: 90%;
         font-size: 50px;
       }

       @media screen and (max-width: 550px){
         width: 90%;
         font-size: 40px;
         font-weight: 700;
       }
     }

     .titleP{
       color: #020202;
       &:nth-child(1){
         font-weight: 400;
         margin-bottom: 10px;
         font-size: 20px;
         opacity: .5;
         @media screen and (max-width: 800px){
           font-size: 17px;
         }
         @media screen and (max-width: 550px){
           font-size: 14px;
         }
       }

     }
     .desc{
       font-weight: 400;
       width: 45%;
       text-align: center;
       margin: 20px auto 30px;
       font-size: 15px;
       opacity: .8;
       color: #1a1a1a;
       @media screen and (max-width: 1200px ){
         width: 60%;
       }
       @media screen and (max-width: 800px){
         width: 80%;
         font-size: 13px;
       }
       @media screen and (max-width: 550px){
         width: 100%;
         font-size: 10spx;
       }
     }
     .buttons{
       display: flex;
       flex-direction: row;
       align-items: center;
       margin-top: 20px;
       justify-content: center;
       gap: 20px;

       a{
         background: #7561ff;
         color: #f1f1f1;
         font-size: 15px;
         padding: 15px 25px;
         border-radius: 25px;
         text-decoration: none;
         transition: .3s;

         &:nth-child(2){
           background: #454459;
         }
         &:hover{
           background: rgba(82, 67, 173, 0.84);
           transition: .3s;
         }
         &:nth-child(2):hover{
           background: #2c2c3a;
         }
       }
       @media screen and (max-width: 550px ){
         width: 100%;
       }
     }
   }

  .logo_slider{
    margin: 180px auto 0;
    width: 50%;
    height: 60px;

    @media screen and (max-width: 1200px ){
      margin: 100px auto 50px;
    }
    @media screen and (max-width: 800px){
      width: 80%;
    }
    @media screen and (max-width: 550px){
      width: 100%;
    }

  }
  @media screen and (max-width: 550px){
    width: 100%;
    padding: 0 10px;
  }
  .slide{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    cursor: pointer;
  }
}
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


.custom_input_container{
  width: 100%;
}
.custom_input_title{
  font-size: 14px;
  width: 100%;
  color: #1f1f1f;
  font-weight: 400;
  margin-bottom: .5rem;
}

.custom_input{
  width: 100%;
  height: 3rem;
  outline: none;
  border: none;
  padding: 0 1rem;
  color: black;
  background-color: #eeeeee;
  font-weight: 300;
  font-size: 15px;
  transition: .3s;
  margin-bottom: 1rem;
  box-shadow: 2px 3px 5px rgba(0, 0, 0, 0.06);

  &:focus{
    border: 1.4px solid black;
    transition: .3s;
  }

  @media screen and (max-width: 600px){
    height: 3rem;
    font-size: 12px;
  }
}